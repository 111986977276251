.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo2 {
    height: 40vmin;
    animation: App-logo-spin infinite 2s linear;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo3 {
    height: 40vmin;
    animation: App-logo-spin infinite 50s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/* .App {
  text-align: center;
  margin-top: 50px;
} */

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

/* button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
} */

.small-button {
  grid-column: span 1;
}

.medium-button {
  grid-column: span 2;
}

.large-button {
  grid-column: span 1;
  grid-row: span 2;
}

.extra-large-button {
  grid-column: span 2;
  grid-row: span 2;
}


button {
  /* padding: 10px 20px; */
  font-size: 24px;
  /* background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer; */
}


.point {
  position: absolute;
  width: 15px;
  height: 3px;
  background-color: red;
  border-radius: 20%;
  /* border-style: solid; */
  /* transition: transform 0.3s ease-out; */
}

